import "./styles.css";

import BannerPromoImg from "../../images/banner_promo.png";
import LeavesBanner from "../../images/leaves_banner.png";

export default function BannerPromo() {
   return (
      <div className="banner-promo">
         <img className="banner-leaves" src={LeavesBanner} alt="" />
         <div className="banner-promo-title">
            <h1 className="resa-bold txt-accent-color uppercase txt-bigger">
               viva com toda a comodidade e lazer <br />
               que você e toda sua família merecem
            </h1>
         </div>
         <img className="banner-promo-img" src={BannerPromoImg} alt="" />

         <div className="banner-promo-list">
            <li className="banner-promo-item txt-normal txt-main-color mazz-bold uppercase">
               piscina
            </li>
            <li className="banner-promo-item txt-normal txt-main-color mazz-bold uppercase">
               salão de festas
            </li>
            <li className="banner-promo-item txt-normal txt-main-color mazz-bold uppercase">
               academia
            </li>
            <li className="banner-promo-item txt-normal txt-main-color mazz-bold uppercase">
               área gourmet
            </li>
            <li className="banner-promo-item txt-normal txt-main-color mazz-bold uppercase">
               workzone
            </li>
         </div>
      </div>
   );
}
