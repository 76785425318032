import foto1 from "../images/photo1.png";
import foto2 from "../images/photo2.png";
import foto3 from "../images/photo3.png";
import foto4 from "../images/photo4.png";
import foto5 from "../images/photo5.png";
import foto6 from "../images/photo6.png";
import foto7 from "../images/photo7.png";
import foto8 from "../images/photo8.png";
import foto9 from "../images/photo9.png";
import foto10 from "../images/photo10.png";
import foto11 from "../images/photo11.png";
import foto12 from "../images/photo12.png";
import foto13 from "../images/photo13.png";
import foto14 from "../images/photo14.png";
import foto15 from "../images/photo15.png";

const photos = [
   {
      id: "1",
      photo: foto1,
   },
   {
      id: "2",
      photo: foto2,
   },
   {
      id: "3",
      photo: foto3,
   },
   {
      id: "4",
      photo: foto4,
   },
   {
      id: "5",
      photo: foto5,
   },
   {
      id: "6",
      photo: foto6,
   },
   {
      id: "7",
      photo: foto7,
   },
   {
      id: "8",
      photo: foto8,
   },
   {
      id: "9",
      photo: foto9,
   },
   {
      id: "10",
      photo: foto10,
   },
   {
      id: "11",
      photo: foto11,
   },
   {
      id: "12",
      photo: foto12,
   },
   {
      id: "13",
      photo: foto13,
   },
   {
      id: "14",
      photo: foto14,
   },
   {
      id: "15",
      photo: foto15,
   },
];
export default photos;
