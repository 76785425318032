import "./styles.css";
import Carousel from "../Carousel";
import photos from "../../configs/dataPhotos";

function BannerCarousel() {
   return (
      <div className="apartment-container txt-orange">
         <div className="apartment-info">
            <span className="apartment-title txt-white txt-big uppercase resa-bold">
               o max trindade vai te conquistar! plante a semente do seu novo
               futuro em um lugar que une conforto, lazer e tudo mais que você
               procura em um empreendimento.
            </span>
            <div className="carousel-apartment-photos">
               <Carousel show={1}>
                  {photos.map((photo) => (
                     <div className="banner-carousel-box" key={photo.id}>
                        <img
                           className="banner-carousel-img"
                           src={photo.photo}
                           alt="Imagem da Sala de Jantar"
                        />
                     </div>
                  ))}
               </Carousel>
            </div>
         </div>
      </div>
   );
}

export default BannerCarousel;
