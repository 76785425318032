import userPolicy from "../../docs/userPolicy.pdf";

import closeBtn from "../../images/close_btn.svg";
import "./styles.css";

export default function Popup({ setModalOpen }) {
   function handleModalClosing() {
      document.body.style.overflow = "auto";
      setModalOpen(false);
   }

   return (
      <div className="popup-background" onClick={() => handleModalClosing()}>
         <img
            className="popup-close-btn"
            src={closeBtn}
            onClick={() => handleModalClosing()}
            alt="fechar"
         />
         <object
            data={userPolicy}
            type="application/pdf"
            width="85%"
            height="85%"
         >
            <a href={userPolicy}>Baixe o PDF</a>
         </object>
      </div>
   );
}
