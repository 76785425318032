import Fachada from "../../images/fachada.png";

import LogoSite from "../../images/logo_site.png";
import Assinatura from "../../images/assinatura.png";
import Building from "../../images/building_sample.png";
import Form from "../Form";

import LeavesHeader from "../../images/leaves_header.png";

import "./styles.css";

function Header() {
   return (
      <div id="form" className="header-site">
         <img className="header-leaves" src={LeavesHeader} alt="" />
         <img className="header-detail" src={Fachada} alt="" />
         <div className="header-content">
            <img
               className="header-building"
               src={Building}
               alt="imagem representativa da construção"
            />
            <div className="header-texts-section">
               <div className="header-merchan">
                  <div className="header-logos">
                     <img
                        className="header-logo"
                        src={LogoSite}
                        alt="logo do site"
                     />
                     <img
                        className="header-assinatura"
                        src={Assinatura}
                        alt="Assinatura construtora"
                     />
                  </div>
                  <div className="header-title">
                     <div className="header-left-titles">
                        <div className="header-merchan-paragraph ">
                           <div className="header-main-title txt-bold txt-bigger txt-accent-color ">
                              <p className="resa-bold title-top">PLANTE A</p>
                              <p className="resa-bold title-mid">SEMENTE DO</p>
                              <p className="resa-bold title-bot">
                                 SEU NOVO FUTURO
                              </p>
                           </div>
                           <p className="header-paragraph mazz-bold txt-main-color txt-bold uppercase txt-normal">
                              Seja muito bem-vindo ao Max Trindade, o lugar
                              perfeito para construir o seu futuro! Apartamentos
                              de 2 quartos c/ suite E área de lazer completa.
                           </p>
                        </div>
                     </div>
                     <Form />
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
}

export default Header;
