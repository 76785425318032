import "./styles.css";
import "../../fonts/Resagokr/ResagokrBold.otf";

import Header from "../../components/Header";
import MapLocalization from "../../components/MapLocalization";
import BannerCarousel from "../../components/BannerCarousel";
import ApartmentDetails from "../../components/ApartmentDetails";
import Footer from "../../components/Footer";
import BannerPromo from "../../components/BannerPromo";
import FloatingPanel from "../../components/FloatingPanel";

function Home() {
   return (
      <div className="homepage-site">
         <FloatingPanel />
         <Header />
         <MapLocalization />
         <BannerCarousel />
         <ApartmentDetails />
         <BannerPromo />
         <Footer />
      </div>
   );
}

export default Home;
