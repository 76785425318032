import "./styles.css";

import TreeIcon from "../../images/tree_icon.png";
import BedIcon from "../../images/bed_icon.png";
import PadlockIcon from "../../images/padlock_icon.png";

export default function Example() {
   return (
      <div className="apartment-details-icons txt-big txt-white mazz-bold">
         <div className="apartment-item">
            <img className="bed-item" src={BedIcon} alt="icon" />
            <p>
               2 QTS
               <br />
               (c/suíte)
            </p>
         </div>
         <div className="apartment-item">
            <img className="apartment-item-icon" src={TreeIcon} alt="icon" />
            <p className="desktop-size">
               Vista definitiva para <br />o Stítio da Trindade
            </p>
            <p className="mobile-size">
               Vista definitiva <br />
               para o Stítio <br />
               da Trindade
            </p>
         </div>
         <div className="apartment-item">
            <img className="apartment-item-icon" src={PadlockIcon} alt="icon" />
            <p>
               Fechaduras
               <br />
               Eletrônicas
            </p>
         </div>
      </div>
   );
}
