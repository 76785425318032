import "./styles.css";
import Maps from "../../images/maps.png";
import LeavesMaps from "../../images/leaves_maps.png";

export default function MapLocalization() {
   return (
      <div className="maps-site">
         <img className="maps-leaves" src={LeavesMaps} alt="" />
         <a href="https://goo.gl/maps/mX7FNFFMLAUTVX2F8" target="__blank">
            <img className="maps-img" src={Maps} alt="Localização do Prédio" />
         </a>
         <div className="maps-information">
            <h2 className="desktop-size maps-information-title txt-white txt-huge uppercase resa-bold">
               perto de tudo <br />
               que você <br />
               precisa para
               <br /> viver bem
            </h2>
            <div className="maps-information-reference txt-normal ">
               <p className="maps-information-paragraph txt-accent-color uppercase mazz-bold">
                  próximo de supermercados, escolas, farmácias, moderno e cheio
                  de comodidade
               </p>
            </div>
         </div>
      </div>
   );
}
